$(document).ready(function () {
    var max = 87;
    $(".readMore").each(function () {
        var str = $(this).text();
        if ($.trim(str).length > max) {
            var subStr = str.substring(0, max);
            var hiddenStr = str.substring(max, $.trim(str).length);
            $(this).empty().html(subStr);
             $(this).append('<span class="dots">...</span>');
            $(this).append(' <a href="javascript:void(0);" class="link">czytaj więcej</a>');
            $(this).append('<span class="addText">' + hiddenStr + '</span>');
        }
    });
    $(".link").click(function () {
         $(this).siblings(".dots").remove();
        $(this).siblings(".addText").contents().unwrap();
        $(this).remove();
    });
});